import React from 'react';
import ReactDOM from 'react-dom';
import LocationSidebar from '../customer_pages/_location-sidebar/location-sidebar';


const locationSidebar = React.createElement(LocationSidebar);

$('body').append('<div id="location-sidebar" />');
const $locationSidebar = ReactDOM.render(locationSidebar, document.getElementById('location-sidebar'));

$('header a[href$="/store-finder"]').on('click', function(e) {
  e.preventDefault();
  $locationSidebar.open();
});
