import React from 'react';
import classNames from 'classnames';

import { builderDispatch } from '../_utils/ui';

import Slidebar from '../_utils/slidebar';
import ZipCodes from '../_libs/zip-codes';
import { isPostalCodeLocal } from '../_utils/validations';
import CartItemsChangeEvents from '../_cart/cart-items-change-events';
import Button from '../_common/button';
import PostalCodeInput from '../_common/postal-code-input';

class LocationSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      zipCode: ''
    };
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  handleOpen = () => {
    const zipCode = ZipCodes.load();
    this.setState((prevState) => {
      return {
        zipCode,
        inputStatus: !isPostalCodeLocal(zipCode) ? 'invalid' : prevState.inputStatus,
      }
    });
    $('.location-sidebar .slidebar-close').focus();
  }

  zipCodeUpdated = () => {
    const { zipCode } = this.state;
    ZipCodes.save(zipCode);
    builderDispatch.dispatch({
      type: CartItemsChangeEvents.ZIP_CODE_CHANGE,
      value: zipCode
    });
  }

  handleZipCodeChange = (e) => {
    const zipCode = e.target.value;

    if (!isPostalCodeLocal(zipCode)) {
      this.setState({
        inputStatus: 'invalid',
        zipCode
      });

      return;
    }

    this.setState({
      inputStatus: 'saved',
      zipCode
    }, this.zipCodeUpdated);
  }

  handleClear = () => {
    this.setState({ zipCode: '', inputStatus: null });
  }

  handleInvalidInput = (location) => {
    this.setState({ inputStatus: 'invalid', zipCode: location });
  }

  handleEnterKey = (e) => {
    const { zipCode } = this.state;

    if (e.key === 'Enter' && isPostalCodeLocal(zipCode)) {
      this.handleClose();
    }
  }

  open = () => {
    this.setState({ open: true });
  }

  renderSearch() {
    const { inputStatus, zipCode } = this.state;

    return (
      <section className="location">
        <i className="fas fa-map-marker-alt" />
        <div>
          <p>
            Update your location to get accurate prices and availability.
          </p>
          <div className="zip-code-input-wrapper">
            <PostalCodeInput
              id='side-bar-zip-code-input'
              inputClassName={classNames({ 'locator-sidebar-invalid-input': inputStatus === 'invalid' })}
              initialValue={zipCode}
              onChange={this.handleZipCodeChange}
              onKeyDown={this.handleEnterKey}
              hideLabel>
              <Button onClick={this.handleClear} className='btn-link clear' text='Clear' icon='fa-times' hideLabel />

              <label htmlFor='side-bar-zip-code-input'>Enter ZIP code</label>
            </PostalCodeInput>
          </div>

          {inputStatus === 'saved' && <p>Location Updated</p>}
        </div>
      </section>
    );
  }

  renderLinks() {
    const { zipCode } = this.state;

    return (
      <section className="links">
        <a href={`/store-finder?address=${zipCode}`}>
          <i className="fas fa-home-alt" />
          <span>
            Local Store
          </span>
        </a>
        <a href="/store-finder">
          <i className="fas fa-store" />
          <span>
            Store Finder
          </span>
        </a>
      </section>
    );
  }

  render() {
    const { open } = this.state;
    const $title = [
      <i key="ls-icon" className="fas fa-map-marker-alt" />,
      <h4 key="ls-h4">Location</h4>
    ];

    return (
      <Slidebar
        className="location-sidebar"
        right
        title={$title}
        open={open}
        onOpen={this.handleOpen}
        onClose={this.handleClose}>
        {this.renderSearch()}
        {this.renderLinks()}
      </Slidebar>
    );
  }
}

export default LocationSidebar;
