'use strict';

// Import Libraries
import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';

class Slidebar extends Component {
  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    this.toggleScrollableBody();
  }

  componentDidUpdate() {
    this.toggleScrollableBody();
  }

  render() {
    const { open, right, className } = this.props,
          position = right ? 'slidebar-right' : 'slidebar-left';

    return (
      <div className={className}>
        <CSSTransition appear in={!!open} timeout={200} classNames="slidebar-backdrop">
          <div className="slidebar-backdrop" onClick={this.handleClose} />
        </CSSTransition>

        <CSSTransition appear in={!!open} timeout={200} classNames="slidebar" onEnter={this.props.onOpen}>
          <aside className={`slidebar ${position}`}>
            {this.renderHeader()}

            {this.props.children}
          </aside>
        </CSSTransition>
      </div>
    );
  }

  renderHeader() {
    return (
      <header className="slidebar-header">
        <div className="slidebar-title">
          {this.props.title}
        </div>
        <button className="slidebar-close btn btn-link" aria-label="Close" onClick={this.handleClose}>
          <i className="fas fa-times" />
        </button>
      </header>
    );
  }

  handleClose() {
    this.props.onClose();
  }

  toggleScrollableBody() {
    $('body').css('overflow-y', this.props.open ? 'hidden' : '');
  }
}

export default Slidebar;
